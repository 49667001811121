export const API_HOST = process.env.API_HOST || 'http://localhost:3000';
export const WIDGET_HOST = process.env.WIDGET_HOST || 'https://stagingwidget.expertbox.io';

export const { BASIC_AUTH_LOGIN, BASIC_AUTH_PASSWORD, LOCAL_ENV } = process.env;

export const LOCALE = process.env.LOCALE || 'en';

export const LANDING_HOST = process.env.LANDING_HOST || 'https://release.clinix.com.py';

export const HOST = process.env.HOST || 'http://localhost:4000';

export const WEBRTC_SERVER_HOST = process.env.WEBRTC_HOST || 'ws://localhost:8188/janus';

export const CABLE_HOST = process.env.CABLE_HOST || 'ws://localhost:8080/cable';

export const APPOINTMENT_PATH = '/appointments/{id}';

export const ALERT_KINDS = {
  success: 'success',
  error: 'error',
  warning: 'warning',
  info: 'info',
};

export const SENTRY_DSN = process.env.SENTRY_DSN
  || 'https://6d45e3fd255747b5b294b389777d5271@o500407.ingest.sentry.io/5631283';
export const RELEASE_TAG = process.env.RELEASE_TAG || '1';

export const CLINIX_PHONE_NUMBER = '+595 991 801 855';
export const CLINIX_FACTURAS_EMAIL = 'facturas@clinix.com.py';
export const DURATION_REGEX = /(?:^(\d+)h (\d+)m$)|(?:^(\d+)h$)|(?:^(\d+)m$)/;
export const PERCENT_REGEX = /(^100(\.0{1,1})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,1})?$)/;
export const COMPANY_URL_REGEX = /^[a-z0-9][a-z0-9-.]*$/;
export const COOKIES_EXPIRED = 86400000;
export const MAX_PASSWORD_LENGTH = 72;
export const MIN_PASSWORD_LENGTH = 6;
export const MIN_CURRENCY_LENGTH = 1000;
export const MAX_CURRENCY_LENGTH = 999999;
export const MIN_COMMISSION_CURRENCY_LENGTH = 0;
export const MAX_COMMISSION_CURRENCY_LENGTH = 9999999;
export const RATE_VCA_COMMISSION_LENGTH = 40;
export const MIN_VCA_COMMISSION_CURRENCY_LENGTH = 10000;
export const MAX_VCA_COMMISSION_CURRENCY_LENGTH = 9999999;
export const MIN_CLINIC_SPECIALITY_PRICE_LENGTH = 60000;
export const MAX_CLINIC_SPECIALITY_PRICE_LENGTH = 9999999;
export const MIN_PERCENTAGE_LENGTH = 0;
export const MAX_PERCENTAGE_LENGTH = 100;
export const MAX_INPUT_SHORT_LENGTH = 400;
export const MAX_INPUT_LENGTH = 400;
export const MAX_INPUT_CONTENT_LENGTH = 1000;
export const MAX_TEXTAREA_CONTENT_SHORT_LENGTH = 255;
export const MAX_TEXTAREA_CONTENT_MIDDLE_LENGTH = 500;
export const MAX_TEXTAREA_CONTENT_LARGE_LENGTH = 1000;
export const MAX_TEXTAREA_CONTENT_LARGEST_LENGTH = 10000;
export const MAX_TEXTAREA_CONTENT_LENGTH = 65535;

export const BRAND_NAME = 'Clinix Inc.';

export const HTTP_STATUSES = {
  unauthorized: 401,
  forbidden: 403,
  gone: 410,
  unprocessableEntity: 422,
  notFound: 404,
  conflict: 409,
};

export const APP_STORE_LINK = 'https://apps.apple.com/es/app/clinix-cl%C3%ADnica-digital/id1546366397';
export const GOOGLE_PLAY_LINK = 'https://play.google.com/store/apps/details?id=py.com.clinix';
export const WHATS_APP_LINKS = {
  en:
    'https://wa.me/595971645215?text=Hello!%20I%E2%80%99m%20writing%20from%20the%20Clinix%20website%20and%20I%20have%20a%20question',
  es: 'https://wa.me/595971645215?text=Hola!%20Necesito%20ayuda%20para%20agendar%20una%20consulta.',
};
