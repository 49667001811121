import Router from 'next/router';
import qs from 'qs';

const serverRedirect = (response, destination, query) => {
  response.writeHead(302, {
    Location: `${destination}${query}`,
  });
  response.end();
};

const clientRedirect = (href, as, query, reload) => (
  reload ? window.location.replace(`${as}${query}`) : Router.push(href, `${as}${query}`)
);

export default ({
  href,
  as,
  response,
  reload,
  query,
}) => {
  const asPath = as || href;
  const queryPath = qs.stringify(query) ? `?${qs.stringify(query)}` : '';

  return response
      ? serverRedirect(response, asPath, queryPath)
      : clientRedirect(href, asPath, queryPath, reload);
};
